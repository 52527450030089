.legalFooter{
    background-color: rgb(59, 59, 59)!important;
    position: fixed !important;
    width:100%!important;
    height: 50px;
    bottom:0px!important;
    z-index: 55!important;
}

.footerText{
    color: white!important;
    /* center text middle and center */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 12px;
    margin: 0px;
    padding: 0px 10px;

}