/* body {
    display: flex;
    align-items: center;
    justify-content: center;
    height:100vh;
    background-color: #d2b7e5;
  }
   */
  .envelope {
    position: relative;
    cursor: pointer;
    box-shadow: 0 0 5px .5px rgba(0, 0, 0, 0.233);
    margin: 100px 0 100px 0;
  }
  
  .back {
    position: relative;
    width:250px;
    height: 200px;
    background-color: #c41f3e;
  }
  
  .letter {
    position: absolute;
    background-color: #F5E3E8;
    background-image: url('../assets/card1.png');
    background-size: contain;
    width:230px;
    height: 180px;
    top:10px;
    left:10px;
    transition: .2s;
    box-shadow: 0 0 5px .5px rgba(0, 0, 0, 0.233);
  }

  .letter2 {
    position: absolute;
    background-color: #F5E3E8;
    background-image: url('../assets/card2.png');
    background-size: contain;
    width:230px;
    height: 180px;
    top:10px;
    left:10px;
    transition: .2s;
    box-shadow: 0 0 5px .5px rgba(0, 0, 0, 0.233);
  }
  
  .letter3 {
    position: absolute;
    background-color: #F5E3E8;
    background-image: url('../assets/card3.png');
    background-size: contain;
    width:230px;
    height: 180px;
    top:10px;
    left:10px;
    transition: .2s;
    box-shadow: 0 0 5px .5px rgba(0, 0, 0, 0.233);
  }

  .letterFR {
    position: absolute;
    background-color: #F5E3E8;
    background-image: url('../assets/card1-french.png');
    background-size: contain;
    width:230px;
    height: 180px;
    top:10px;
    left:10px;
    transition: .2s;
    box-shadow: 0 0 5px .5px rgba(0, 0, 0, 0.233);
  }

  .letter2FR {
    position: absolute;
    background-color: #F5E3E8;
    background-image: url('../assets/card2-french.png');
    background-size: contain;
    width:230px;
    height: 180px;
    top:10px;
    left:10px;
    transition: .2s;
    box-shadow: 0 0 5px .5px rgba(0, 0, 0, 0.233);
  }
  
  .letter3FR {
    position: absolute;
    background-color: #F5E3E8;
    background-image: url('../assets/card3-french.png');
    background-size: contain;
    width:230px;
    height: 180px;
    top:10px;
    left:10px;
    transition: .2s;
    box-shadow: 0 0 5px .5px rgba(0, 0, 0, 0.233);
  }
  
  /* .letter:before {
    position: absolute;
    content:"";
    background-color: #333;
    width: 80px;
    height: 80px;
    top:75px;
    left:75px;
  }
  
  .letter:after {
    position: absolute;
    content:"";
    width:30px;
    height:30px;
    background-color: #fff;
    top:82px;
    left: 82px;
    box-shadow: 36px 0 #fff, 36px 36px #fff, 0px 36px #fff;
  } */
  
  .text {
    text-align: center;
    font-size: 17px;
    font-family: arial;
    margin-top:20px;
    font-weight: bold;
  }
  
  /* .text:before, .text:after {
    content:"";
    position: absolute;
    width: 5px;
    border-radius:10px;
    background-color: #333;
    height: 20px;
    top:60px;
  } */
  
  .text:before {
    left:108px;
    transform: rotate(-25deg);
  }
  
  .text:after {
    left:118px;
    transform: rotate(25deg);
  }
  
  .front {
    position: absolute;
    border-right: 130px solid #eeeeee;
    border-top: 100px solid transparent;
    border-bottom: 100px solid transparent;
    height:0;
    width:0;
    top:0;
    left:120px;
    z-index:3;
  }
  
  .front:before {
    content:"";
    position: absolute;
    border-left: 130px solid #eeeeee;
    border-top: 100px solid transparent;
    border-bottom: 100px solid transparent;
    height:0;
    width:0;
    top:-100px;
    left:-120px;
  }
  
  .front:after {
    content:"";
    position: absolute;
    border-bottom: 105px solid #e0e0e0;
    border-left: 125px solid transparent;
    border-right:125px solid transparent;
    height:0;
    width:0;
    top:-5px;
    left:-120px;
  }
  
  .top {
    position: absolute;
    border-top: 105px solid #e4e4e4;
    border-left: 125px solid transparent;
    border-right:125px solid transparent;
    height:0;
    width:0;
    top:0;
    transform-origin: top;
    transition: .4s;
  }
  
  .shadow {
    position: absolute;
    background-color: rgba(0,0,0,0.1);
    width:250px;
    height:10px;
    top:220px;
    border-radius:50%;
  }
  
  .envelope:hover .top {
    transform: rotateX(160deg);
  }
  
  .envelope:hover .letter{
    transform: translateY(-100px);
    z-index:2;
  }

  .envelope:hover .letter2{
    transform: translateY(-100px);
    z-index:2;
  }

  .envelope:hover .letter3{
    transform: translateY(-100px);
    z-index:2;
  }
  .envelope:hover .letterFR{
    transform: translateY(-100px);
    z-index:2;
  }

  .envelope:hover .letter2FR{
    transform: translateY(-100px);
    z-index:2;
  }

  .envelope:hover .letter3FR{
    transform: translateY(-100px);
    z-index:2;
  }
  /* if screen height is smaller than 700px */
  @media screen and (max-height: 700px) {
      .card_container{
        display: flex;
        flex-direction: column;
        align-items: center;
        /* justify-content: center; */
        height: 60vh!important;
        background-color: #e3ebf7;
        overflow-y: scroll!important;
        margin-bottom: 100px!important;
    }
  }

  .card_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    height: 70vh;
    background-color: #e3ebf7;
    overflow-y: scroll!important;
    margin-bottom: 100px!important;
}

  .card_selection{
    width: 100%;
    padding: 20px 0px 60px 0px;
    background-color: rgb(248, 248, 248);
    
  }

  .card_selection_button {
    background-color: #c41f3e !important;
    color: white!important;
    /* font-weight: bold!important; */
    width: 90%!important;
    height: 50px!important;
    margin: 10px 20px!important;
    text-transform: inherit!important;
    font-family: 'Whiteney-Book'!important;
    border-radius: 8px!important;
    font-size: 1rem!important;
  }

  .steps{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-top: 0px;
    margin-left: 20px;
    color: #c41f3e;
    font-size: 15px;
  }

  .heading{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 20px;
    color: black;
    font-size: 20px;
    text-align: left;
  }
  
  .subheading{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 20px;
    color: black;
    font-size: 15px;
    text-align: left;
  }