/* Desktop Sizing */

    

.logo{
    width: 250px;
    margin: 0 auto;
}

.appbar{
    background-color: white!important;
    box-shadow: none!important;
}