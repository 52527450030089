.card_input{
    width: 100%!important;
}

.modal{
    background-color: #F5E3E8!important;
}

.nextbutton{
    background-color: #c41f3e !important;
    color: white!important;
    /* font-weight: bold!important; */
    width: 90%!important;
    height: 50px!important;
    margin: 10px 20px!important;
    text-transform: inherit!important;
    font-family: 'Whiteney-Book'!important;
    border-radius: 8px!important;
    font-size: 1rem!important;
}

.textfield{
    border-color:#c41f3e!important;
}