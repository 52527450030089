.nextButton {
    background-color: #c41f3e !important;
    color: white !important;
    /* font-weight: bold!important; */
    width: 90% !important;
    height: 50px !important;
    margin: 10px 20px !important;
    text-transform: inherit !important;
    font-family: 'Whiteney-Book' !important;
    border-radius: 8px !important;
    font-size: 1rem !important;
}

.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    overflow: scroll!important;
    height: 150vh;
    margin-bottom: 800px!important;
}

