/* body {
    display: flex;
    align-items: center;
    justify-content: center;
    height:100vh;
    background-color: #00bbf9;
  }
   */

  .birthdayCard {
    position: relative;
    margin: 0 auto;
    width: 350px;
    height:250px;
    cursor: pointer;
    transform-style: preserve-3d;
    transform: perspective(2500px);
    transition: 4s;
    background-color: #F5E3E8!important ;
  }
  
  .birthdayCard:hover {
    transform: perspective(2500px) rotate(5deg);
    box-shadow: inset 100px 20px 100px rgba(0,0,0,.15), 0 10px 100px rgba(0,0,0,0.3);
    background-color: #F5E3E8!important ;
  }
  
  .birthdayCard:hover .cardFront {
    transform: rotateY(-160deg); 
    background-image: none;
    background-color: #F5E3E8!important ;
  }
  .birthdayCard:hover .cardFront2 {
    transform: rotateY(-160deg); 
    background-image: none;
    background-color: #F5E3E8!important ;
  }
  .birthdayCard:hover .cardFront3 {
    transform: rotateY(-160deg); 
    background-image: none;
    background-color: #F5E3E8!important ;
  }
  .birthdayCard:hover .cardFrontFR {
    transform: rotateY(-160deg); 
    background-image: none;
    background-color: #F5E3E8!important ;
  }
  .birthdayCard:hover .cardFront2FR {
    transform: rotateY(-160deg); 
    background-image: none;
    background-color: #F5E3E8!important ;
  }
  .birthdayCard:hover .cardFront3FR {
    transform: rotateY(-160deg); 
    background-image: none;
    background-color: #F5E3E8!important ;
  }
  
  .birthdayCard:hover .front-text {
    display: none;
  }
  
  .birthdayCard:hover .wrap-deco {
    display: none;
  }
  
  .birthdayCard:hover .wrap-decoTwo {
    display: none;
  }
  
  .birthdayCard:hover .plate {
    display: none;
  }
  
  .cardFront {
    position: relative;
    background-color: #e3ebf7;
    background-image: url('../assets/card1.png');
    background-size: contain;
    width: 350px;
    height:250px;
    overflow: hidden;
    transform-origin: left;
    box-shadow: inset 100px 20px 100px rgba(0,0,0,.13), 30px 0 50px rgba(0,0,0,0.1);
    transition: .4s;
  }

  .cardFront2 {
    position: relative;
    background-color: #e3ebf7;
    background-image: url('../assets/card2.png');
    background-size: contain;
    width: 350px;
    height:250px;
    overflow: hidden;
    transform-origin: left;
    box-shadow: inset 100px 20px 100px rgba(0,0,0,.13), 30px 0 50px rgba(0,0,0,0.1);
    transition: .4s;
  }
  
  .cardFront3 {
    position: relative;
    background-color: #e3ebf7;
    background-image: url('../assets/card3.png');
    background-size: contain;
    width: 350px;
    height:250px;
    overflow: hidden;
    transform-origin: left;
    box-shadow: inset 100px 20px 100px rgba(0,0,0,.13), 30px 0 50px rgba(0,0,0,0.1);
    transition: .4s;
  }
  .cardFrontFR {
    position: relative;
    background-color: #e3ebf7;
    background-image: url('../assets/card1-french.png');
    background-size: contain;
    width: 350px;
    height:250px;
    overflow: hidden;
    transform-origin: left;
    box-shadow: inset 100px 20px 100px rgba(0,0,0,.13), 30px 0 50px rgba(0,0,0,0.1);
    transition: .4s;
  }

  .cardFront2FR {
    position: relative;
    background-color: #e3ebf7;
    background-image: url('../assets/card2-french.png');
    background-size: contain;
    width: 350px;
    height:250px;
    overflow: hidden;
    transform-origin: left;
    box-shadow: inset 100px 20px 100px rgba(0,0,0,.13), 30px 0 50px rgba(0,0,0,0.1);
    transition: .4s;
  }
  
  .cardFront3FR {
    position: relative;
    background-color: #e3ebf7;
    background-image: url('../assets/card3-french.png');
    background-size: contain;
    width: 350px;
    height:250px;
    overflow: hidden;
    transform-origin: left;
    box-shadow: inset 100px 20px 100px rgba(0,0,0,.13), 30px 0 50px rgba(0,0,0,0.1);
    transition: .4s;
  }
  
  .happy, .toyou {
    position: relative;
    font-family: didot;
    text-align: center;
    backface-visibility: hidden;
    font-size: 30px; 
  }
  
  .happy {
    top:198px;
  }
  
  .toyou {
    top:123px;
  }
  
  .bday {
    position: relative;
    font-family: arial;
    font-size: 35px;
    text-align: center;
    top:163px;
  }
  
 
  
 
 
  .cardInside {
    position: absolute;
    background-color: #F5E3E8;
    width: 350px;
    height:250px;
    z-index:-1;
    left:0;
    top:0;
    /* box-shadow: inset 100px 20px 100px rgba(0,0,0,0.2); */
  }
  
  .inside_text {
    position: relative;
    top:-160px;
    transform: scale(0.7);
  }
  
  .wishes {
    position: relative;
    top:50px;
    margin: 25px;
  }
  
  /* p {
    font-family: 'Brush Script MT', cursive;
    color: #333;
  } */
  
  .name {
    margin-left:150px;
  }
  
  
  
  
  .recipientCardContainer{
    /* center in the middle of the page */
    display: flex;
    align-items: center;
    justify-content: center;
    height:100vh;
    background-color: #fff;


  }

  .cibcLogo{
    /* position in the top center */
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 200px;
  }