@font-face {
    font-family: 'Whiteney-Regular';
    src: url('../styles/fonts/whitneymedium.otf') format('opentype');
}

@font-face {
    font-family: 'Whiteney-Book';
    src: url('../styles/fonts/whitneybook.otf') format('opentype');
}

.ctaImage{
    width: 100%
}

.ctaBody{
    /* width: 90%; */
    text-align: center;
    top: 50%!important;
    margin: 100px 25px 15px 25px;
    font-size:  50px;
    line-height: 1.5rem;
    font-family: 'Whiteney-Regular';

}

.languageButton{
    background-color: #c41f3e !important;
    color: white!important;
    /* font-weight: bold!important; */
    width: 90%!important;
    height: 50px!important;
    top: 100px!important;
    margin: 10px 20px!important;
    text-transform: inherit!important;
    font-family: 'Whiteney-Book'!important;
    border-radius: 8px!important;
    font-size: 1rem!important;

}