@media screen and (min-width: 768px) {
  body{
  /* width 50% and center page */
    max-width: 50%;
    margin: 0 auto;
    left: 50%;
    transform: translateX(50%);
  }
}
  


body {
  margin: 0;
  font-family: "Whiteney-Book";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* height: 100vh!important;
  max-height: 100vh!important; */
  width: 100vw!important;
  /* overflow: hidden!important; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
  color: #c41f3e!important;
  border-color: #c41f3e!important;
}